(function() { 'use strict';
  // Selectors
  // ---------
  window.$WINDOW = $(window);
  window.$DOCUMENT = $(document);
  window.$HTML = $(document.documentElement);
  window.$BODY = $(document.body);
  window.$HEADER = $('.header');

  // Helpers
  // -------
  window.IS_MOBILE = !!navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)|(Windows Phone)/i);
  window.IS_TOUCH_DEVICE = 'ontouchend' in document || !!navigator.msMaxTouchPoints || !!navigator.maxTouchPoints;
  window.HEADER_HEIGHT = $HEADER.height();


  // Masked input
  // ------------
  $('input[type="date"]').attr('type', 'text');

  // Phone
  $('input[type="tel"]').inputmask({
    regex: '\\+7 \\([0-6,9]\\d\\d\\) \\d{3}(\\-\\d{2}){2}',
    placeholder: '+7 (___) ___-__-__'
  });


  /*if( !IS_MOBILE )
    $WINDOW
      .on('scroll', function() {
        if( $WINDOW.scrollTop() > 0 ) {
          if( !$HEADER.hasClass('is-fixed') ) {
            $HEADER.addClass('is-fixed').next().css('padding-top', HEADER_HEIGHT);

            $('.header-advantages > ul').slideUp(300, function() {
              $HEADER.next().animate({paddingTop: $HEADER.height()}, 200);
            });
          }
        } else {
          $('.header-advantages > ul').slideDown(300);
          $HEADER.removeClass('is-fixed').next().css('padding-top', 0);
        }
      });*/


  $DOCUMENT
    // Scroll to
    // ---------
    .on('click.js-scroll-to', '.js-scroll-to', function(e) {
      e.preventDefault();

      var $lnk = $(this),
          $elemToScroll = $($lnk.attr('href').substr($lnk.attr('href').lastIndexOf('#'))),
          speed = $lnk.data('scrollSpeed') || 150,
          offset = $lnk.data('scrollOffset') || 0;

      $WINDOW.scrollTo($elemToScroll, {axis: 'y', duration: speed, offset: offset});
    })

    // Main price tabs
    // ---------------
    .on('click.main-price-tabs', '.main-price-container .main-price-tabs > a', function(e) {
      e.preventDefault();

      var $tab = $(this);

      if( !$tab.hasClass('active') ) {
        $tab.addClass('active').siblings().removeClass('active');

        $tab
          .parents('.main-price-container')
          .find('.main-price-content')
          .children().eq($tab.index()).addClass('active')
          .siblings().removeClass('active');
      }
    })

    // Main price quick links
    // ----------------------
    .on('click.main-price-quick-links', '.main-price-container .main-price-table-quick-links a', function(e) {
      e.preventDefault();

      var $link = $(this);

      if( !$link.parent().hasClass('active') ) {
        $link
          .parent().addClass('active')
          .siblings().removeClass('active')
          .first().parent().addClass('selected')
          .parent().find('.price-table-container .price-table').addClass('selected')
          .find($link.attr('href'))
          .parent().addClass('active')
          .siblings().removeClass('active');
      }
    })

    // Faq list
    // --------
    .on('click.faq-list', '.faq-list > div', function(e) {
      e.preventDefault();

      $(this).toggleClass('active').children('div:last-child').slideToggle(300);
    })
    .trigger('scroll');


  // FancyBox
  // --------
  $.extend(true, $.fancybox.defaults, {
    autoFocus: false,
    loop: true,
    infobar: false,
    smallBtn: true,
    buttons: [
      false,
      false,
      false,
      'close'
    ],
    idleTime: false,
    transitionEffect: 'slide',
    hash: false,
    mobile: {
      clickSlide: 'close'
    },
    lang: 'ru',
    i18n: {
      ru: {
        CLOSE: 'Закрыть',
        NEXT: 'Следующий слайд',
        PREV: 'Предыдущий слайд',
        ERROR: 'Ошибка загрузки. <br/>Пожалуйста, попробуйте позже.',
        PLAY_START: 'Запустить показ слайдов',
        PLAY_STOP: 'Остановить показ слайдов',
        FULL_SCREEN: 'В полный экран',
        THUMBS: 'Миниатюры',
        DOWNLOAD: 'Скачать',
        SHARE: 'Поделиться',
        ZOOM: 'Масштаб'
      }
    },

    beforeLoad: function(widget, current) {
      switch(current.type) {
        case 'image':
          current.opts.protect = true;
          break;

        case 'inline':
          current.opts.touch = false;
          break;
      }
    },
    afterLoad: function(widget, current) {
      if( current.contentType === 'html' && !IS_MOBILE ) {
        current.$content.find('.js-autofocus-inp').trigger('focus');
      }
    }
  });


  // Slick sliders
  // -------------
  var slickSettings = {
    autoplay: true,
    autoplaySpeed: 6000,
    prevArrow: '<div class="slick-prev"></div>',
    nextArrow: '<div class="slick-next"></div>',
    customPaging: function() { return $('<div />'); },
    draggable: false
  };

  // Main rotator
  $('.main-rotator').slick($.extend({}, slickSettings, {
    centerMode: true,
    centerPadding: '0px',
    fade: true,
    arrows: false,
    dots: true,
    slide: '.main-rotator-slide',
    appendDots: '#main-rotator-dots'
  }));


  // Portfolio filter
  // ----------------
  if( $('.portfolio-page-block-container').length ) {
    var $portfolioGrid = $('.portfolio-page-block-container .portfolio-items').isotope({
          itemSelector: '.list-view-item',
          layoutMode: 'fitRows',
          percentPosition: true,
          transitionDuration: '0.5s',
          containerStyle: null
        }),
        $links = $('.portfolio-page-block-container .quick-link-list a');

    $links.on('click.portfolio-filter', function(e) {
      e.preventDefault();

      location.hash = encodeURIComponent( $(this).data('filterValue') );
    });

    $WINDOW.on('hashchange', function(e) {
      var filterValue = decodeURIComponent( location.hash.slice(1) );

      if( filterValue.length ) {
        $links.removeClass('active').filter('[data-filter-value="' + filterValue + '"]').addClass('active');

        if( filterValue === 'all' ) {
          $portfolioGrid.isotope({ filter: '*'});
        } else {
          $portfolioGrid.isotope({ filter: '[data-filter-section="' + filterValue  + '"]'});
        }
      }
    });
  }


  // Callbacks
  // ---------
  /*CALLBACKS = {
    reachGoalPhoneOrder: function() {
      if( typeof yaCounter00000000 === 'object' )
        yaCounter00000000.reachGoal('phone_order');
    }
  };*/


  // Triggers
  // --------
  /*if( !IS_MOBILE )
    $WINDOW.trigger('scroll');*/

})();